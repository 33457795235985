<div class="wrapper">
    <a routerLink="/Thor">
        <div id="Thor" class="image">
            <img src="assets/home/THOR mechatronics logo.svg" />
        </div>
    </a>
    <a href="https://pederfest.nl/" target="_blank">
        <div id="PederFest" class="image">
            <img src="assets/home/PederFest Logo.svg" />
        </div>
    </a>
    <a routerLink="/PJG-alters">
        <div id="PJ" class="image">
            <img src="assets/home/PJ Alters Logo.svg" />
        </div>
    </a>
    <a href="https://vanirdesign.nl/" target="_blank">
        <div id="Vanir" class="image">
            <img src="assets/home/Vanir Design Logo Small.svg" />
        </div>
    </a>
</div>