import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thor',
  templateUrl: './thor.component.html',
  styleUrl: './thor.component.scss',
  standalone: false
})

export class ThorComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
