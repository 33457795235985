import { Component } from '@angular/core';

@Component({
    selector: 'app-kastjes',
    templateUrl: './kastjes.component.html',
    styleUrls: ['./kastjes.component.scss'],
    standalone: false
})

export class KastjesComponent {
  title = 'Kastjes';

  playFromTop(location: string) {
    var visibles = document.getElementsByClassName("visible");
    var visibleElement = visibles.item(0) as HTMLElement;
    var isOpen: boolean = false;
    if (visibleElement?.id.endsWith("1")) {
      isOpen = true
    }
    visibleElement?.classList.remove("visible");
    switch (location) {
      case "links": {
        if (!isOpen) {
          this.HandleVideo(visibleElement.id, "A1");
          this.PlayAudio("links");
        } else if (visibleElement?.id === "A1") {
          this.HandleVideo(visibleElement.id, "A2", "links");
        } else if (visibleElement?.id === "B1") {
          this.PlayPreviousClose(visibleElement.id, "B2", "A1", "midden", "links");
        } else if (visibleElement?.id === "C1") {
          this.PlayPreviousClose(visibleElement.id, "C2", "A1", "rechts", "links");
        }
        break;
      }
      case "midden": {
        if (!isOpen) {
          this.HandleVideo(visibleElement.id, "B1");
          this.PlayAudio("midden");
        } else if (visibleElement?.id === "B1") {
          this.HandleVideo(visibleElement.id, "B2", "midden");
        } else if (visibleElement?.id === "A1") {
          this.PlayPreviousClose(visibleElement.id, "A2", "B1", "links", "midden");
        } else if (visibleElement?.id === "C1") {
          this.PlayPreviousClose(visibleElement.id, "C2", "B1", "rechts", "midden");
        }
        break;
      }
      case "rechts": {
        if (!isOpen) {
          this.HandleVideo(visibleElement.id, "C1");
          this.PlayAudio("rechts");
        } else if (visibleElement?.id === "C1") {
          this.HandleVideo(visibleElement.id, "C2", "rechts");
        } else if (visibleElement?.id === "B1") {
          this.PlayPreviousClose(visibleElement.id, "B2", "C1", "midden", "rechts");
        } else if (visibleElement?.id === "A1") {
          this.PlayPreviousClose(visibleElement.id, "A2", "C1", "links", "rechts");
        }
        break;
      }
    }
  }

  HandleVideo(prevVid: string, vidName: string, audioToStop: string = "") {
    var video = document.getElementById(vidName) as HTMLVideoElement;
    var prevVideo;
    if (prevVid !== "") {
      prevVideo = document.getElementById(prevVid) as HTMLVideoElement;
      prevVideo.currentTime = 0;
    }
    video.classList.add("visible");
    video.classList.add("playing");
    video.play();
    video.onended = () => { 
      video.classList.remove("playing");
      if (audioToStop !== "") this.StopAudio(audioToStop);
    }
    prevVideo?.classList.remove("visible");
  }

  PlayAudio(name: string) {
    var audio = document.getElementById(name) as HTMLAudioElement;
    audio.muted = false;
    audio.playbackRate = 1;
    audio.play();
  }

  StopAudio(name: string) {    
    var audio = document.getElementById(name) as HTMLAudioElement;
    audio.muted = true;
    audio.playbackRate = 16.0;
  }

  PlayPreviousClose(prevVid: string, nextVid: string, goalVid: string, audioToStop: string, audioToPlay: string) {
    var prevVideo = document.getElementById(prevVid) as HTMLVideoElement;
    var nextVideo = document.getElementById(nextVid) as HTMLVideoElement;
    nextVideo.classList.add("visible");
    nextVideo.classList.add("playing");
    nextVideo.play();
    prevVideo.currentTime = 0;
    prevVideo.classList.remove("visible");
    nextVideo.onended = () => {
      this.StopAudio(audioToStop);
      this.HandleVideo("", goalVid);
      nextVideo.currentTime = 0;
      nextVideo.classList.remove("visible");
      nextVideo.classList.remove("playing");
      this.PlayAudio(audioToPlay);
    };
  }
}

