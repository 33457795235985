<div class="body">
    <div class="linker kastje" (click)="playFromTop('links')">
    </div>
    <div class="middel kastje" (click)="playFromTop('midden')">
    </div>
    <div class="rechter kastje" (click)="playFromTop('rechts')">
    </div>
  
    <video id="2" class="source video visible" muted>
      <source src="assets/kastjes/C1.mp4" />
    </video>
    <video id="A1" class="source video" muted>
      <source src="assets/kastjes/A1.mp4" />
    </video>
    <video id="A2" class="source video" muted>
      <source src="assets/kastjes/A2.mp4" />
    </video>
    <video id="B1" class="source video" muted>
      <source src="assets/kastjes/B1.mp4" />
    </video>
    <video id="B2" class="source video" muted>
      <source src="assets/kastjes/B2.mp4" />
    </video>
    <video id="C1" class="source video" muted>
      <source src="assets/kastjes/C1.mp4" />
    </video>
    <video id="C2" class="source video" muted>
      <source src="assets/kastjes/C2.mp4" />
    </video>
  
    <audio id="links">
      <source src="assets/Links.mp3" />
    </audio>
    <audio id="midden">
      <source src="assets/Midden.mp3" />
    </audio>
    <audio id="rechts">
      <source src="assets/Rechts.mp3" />
    </audio>
  </div>