import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mtg',
    templateUrl: './mtg.component.html',
    styleUrls: ['./mtg.component.scss'],
    standalone: false
})

export class MtgComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
