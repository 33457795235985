<div class="wrapper">
    <p class="text">
        Hieronder vindt je een overzicht van alle verschillende projecten en opdrachten waar ik me mee heb beziggehouden de afgelopen jaren. Ik heb een heel breed portfolio, omdat ik ook graag met veel verschillende projecten bezig ben.
    </p>

    <h1>Projecten</h1>

    <ul class="projectlijst">
        <li>
            <button (click)="showSoftware=!showSoftware">Software</button>
            <ul *ngIf="showSoftware">
                <li>
                    Website ontwerp en onderhoud
                    <ul>
                        <li><a href="https://www.villaparkeindhoven.nl">Villaparkeindhoven.nl</a></li>
                        <li><a href="https://houbenvanmierlo.nl">Houbenvanmierlo.nl</a></li>
                        <li><a href="https://jasmijn.online">Jasmijn.online</a></li>
                        <li><a href="https://vanirdesign.nl">Vanirdesign.nl</a></li>
                        <li><a href="https://pederfest.nl">Pederfest.nl</a></li>
                        <li><a href="https://sannebeijer.nl">Sannebeijer.nl</a></li>
                        <li><a href="https://pedergeurts.nl">Pedergeurts.nl</a></li>
                    </ul>
                </li>

                <li>
                    (Web)Apps
                    <ul>
                        <li><a routerLink="/Kastjes">Keukenkastjes (Vanir design)</a></li>
                        <li><a href="https://submarinechannel.com/motioncomics/ascent-from-akeron-interactive-motion-comic/">Akeron episode 3 & 4 (Submarine)</a></li>
                        <li>Franswaski</li>
                        <li>Mobiel scanner (9de.online)</li>
                    </ul>
                </li>

                <li>
                    Oude projecten
                    <ul>
                        <li>Mldrprojectenadvies.nl</li>
                        <li>Blckfashion.nl</li>
                    </ul>
                </li>
            </ul>
        </li>

        <li>
            <button (click)="showHardware=!showHardware">Hardware</button>
            <ul *ngIf="showHardware">
                <li><a href="https://vanirdesign.nl/?page_id=25">Interactieve stellage voor een onderzoek (Vanir design)</a></li>
                <li>Mobiel scanner (9de.online)</li>
                <li>Printplaatje voor een electrische fietsbel (Wonderlab)</li>
                <li>Ontwerp voor een telefoonhoes met accu (9de.online)</li>
            </ul>
        </li>

        <li>
            <button (click)="showSupport=!showSupport">Technische Support</button>
            <ul *ngIf="showSupport">
                <li>SOEasy PaySystems (Gelredome)</li>
                <li>Netwerkbeheer (EventsIT)</li>
                <li>Bijlesleraar</li>
            </ul>
        </li>

        <li>
            <button (click)="showMuziek=!showMuziek">Muziek</button>
            <ul *ngIf="showMuziek">
                <li><a href="https://pederfest.nl">Pederfest</a></li>
                <li>DJ (Altstadt)</li>
                <li>DJ (Groene Engel)</li>
                <li>DJ (Trouwerijen)</li>
            </ul>
        </li>

        <li>
            <button (click)="showHoreca=!showHoreca">Horeca</button>
            <ul *ngIf="showHoreca">
                <li>Barhoofd (Kazerne)</li>
                <li>Inval barman (Altstadt)</li>
            </ul>
        </li>

        <li>
            <button (click)="showOpleiding=!showOpleiding">Opleidingen en Certificaten</button>
            <ul *ngIf="showOpleiding">
                <li>Scrum Master training (ScrumIt)</li>
                <li>Microsoft 70-480</li>
                <li>Minor Game Design (HvA)</li>
                <li>Mechatronica (Fontys)</li>
                <li>Gymansium (Wolfert Dalton)</li>
            </ul>
        </li>
    </ul>
</div>