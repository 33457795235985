<div class="wrapper">
    <a routerLink="/Thor">
        <div id="Thor" class="image">
            <img src="assets/home/THOR mechatronics logo.svg" />
        </div>
    </a>

    <p class="text">
        Welkom op mijn website. Hier ga ik wat vertellen over mijzelf, mijn bedrijfje, mijn hobby's en waarom je wat aan mij zou kunnen hebben!
    </p>
</div>