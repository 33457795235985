import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-construction',
    templateUrl: './construction.component.html',
    styleUrls: ['./construction.component.scss'],
    standalone: false
})

export class ConstructionComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
