import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-portfolio',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.scss'],
    standalone: false
})
export class PortfolioComponent implements OnInit {
  showSoftware: boolean = false;
  showHardware: boolean = false;
  showSupport: boolean = false;
  showMuziek: boolean = false;
  showHoreca: boolean = false;
  showOpleiding: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
